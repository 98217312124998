import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import toast from 'react-hot-toast'
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import Loading from "../../../common/Loading";
import useUrlQuery from "../../../common/hooks/useUrlQuery";

const SettingsPersonalInfoAddOAuthRedirectRoute = () => {
    const {provider} = useParams()

    const navigate = useNavigate()

    const code = useUrlQuery('code')
    const state = useUrlQuery('state')
    const scope = useUrlQuery('scope ')

    const getAdditional = (provider: string | undefined) => {
        switch (provider?.toUpperCase()) {
            case 'NAVER':
                return state
            case 'GOOGLE':
                return scope
            default:
                return ''
        }
    }

    const getApiUrl = (
        provider: 'KAKAO' | 'NAVER' | 'GOOGLE' | 'FACEBOOK',
        code: string | null = null,
        state: string | null = null,
        scope: string | null = null,
    ): string => {
        if (provider === 'KAKAO') {
            return `/v1/users/settings/personal-info/add-oauth/kakao?code=${code}`
        } else if (provider === 'NAVER') {
            return `/v1/users/settings/personal-info/add-oauth/naver?code=${code}&state=${state}`
        } else if (provider === 'GOOGLE') {
            return `/v1/users/settings/personal-info/add-oauth/google?code=${code}&scope=${scope}`
        } else if (provider === 'FACEBOOK') {
            return `/v1/users/settings/personal-info/add-oauth/facebook?code=${code}`
        } else {
            return ''
        }
    }

    React.useEffect(() => {
        if (code !== null) {
            BooksitoutServer
                .post(getApiUrl(provider?.toUpperCase() as 'NAVER' | 'GOOGLE' | 'KAKAO' | 'FACEBOOK', code, getAdditional(provider)))
                .then((res) => res.data)
                .then(() => {
                    toast.success(`계정을 추가했어요.`)
                    navigate(`/settings/personal-infos`)
                })
                .catch((e) => {
                    toast.error(e.message)
                    navigate(`/settings/personal-infos`)
                })
        }
    }, [code, provider])

    return <Loading size={100} message={`로그인 하고 있어요`}/>

}

export default SettingsPersonalInfoAddOAuthRedirectRoute