import {useEffect, useState} from "react";
import AppUserSettingsPersonalInfoResponse from "./AppUserSettingsPersonalInfoResponse";
import {BooksitoutServer} from "../../../config/BooksitoutServer";

const useAppUserSettingsPersonalInfo = () => {
    const [isLoading, setIsLoading] = useState<boolean>()
    const [isError, setIsError] = useState<boolean>()

    const [info, setInfo] = useState<AppUserSettingsPersonalInfoResponse | null>(null)

    useEffect(() => {
        BooksitoutServer
            .get(`/v1/users/settings/personal-info`)
            .then(res => setInfo(res.data))
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false))
    }, [])

    return [info, isLoading, isError] as const
}

export default useAppUserSettingsPersonalInfo