import React from 'react'
import styled from 'styled-components';
import RouteContainer from "../../../common/styles/RouteContainer";
import useAppUserProfile from "../useAppUserProfile";
import {useParams} from "react-router";
import {Card} from 'react-bootstrap';
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import BookMineCard from "../../book/mine/BookMineCard";
import CardTitle from '../../../common/styles/CardTitle';
import booksitoutIcon from '../../../config/BooksitoutIcon';
import NoContent from "../../../common/NoContent";
import ProfileUserCard from "./ProfileUserCard";

const ProfileRoute = () => {
    const {userId} = useParams()
    const [appUserWithBooks, isLoading, isError] = useAppUserProfile(userId, 0, 12)

    if (isLoading) {
        return <></>
    }

    if (isError || appUserWithBooks == null) {
        return <NoContent message={'유저가 없거나 탈퇴한 유저에요.'}/>
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <ProfileUserCard user={appUserWithBooks.user}/>

            <RowSpacer/>
            <a href={`/users/${userId}/books?page=1`}>
                <Card>
                    <CardBodyContentContainer>
                        <CardTitle icon={<booksitoutIcon.book/>} title={'공유 설정한 책'} url={``}/>

                        <Row>
                            {
                                appUserWithBooks.books.map(book =>
                                    <Col>
                                        <BookMineCard book={book}/>
                                    </Col>
                                )
                            }
                        </Row>
                    </CardBodyContentContainer>
                </Card>
            </a>

            <RowSpacer/>
        </RouteContainer>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})``

const Col = styled.div.attrs({
    className: 'col-6 col-md-4 col-lg-3 col-xl-2'
})`
    pointer-events: none;
`

const ProfileContainer = styled.div`
    display: flex;
`

const ProfileName = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
`

const ProfileDate = styled.h1.attrs({
    className: 'text-secondary'
})`
    font-size: 1rem;
`

const ProfileImage = styled.img.attrs({
    className: 'rounded'
})`
    width: 100px;
    height: 100px;
`

export default ProfileRoute
