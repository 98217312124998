import appleAppstoreIcon from './apple-appstore.png'
import generalLink from './link.png'
import booksitoutIcon from './booksitout.png'
import purrfitTrackerIcon from './purrfitTracker.png'
import substrackIcon from './substrack.png'
import pianomanIcon from './pianoman.png'
import featuredDays from './featureddaysicon.png'

const BooksitoutWebsiteIcon = {
	appleAppstore: appleAppstoreIcon,
	link: generalLink,

	booksitout: booksitoutIcon,
	purrfitTracker: purrfitTrackerIcon,
	substrack: substrackIcon,
	pianoman: pianomanIcon,
	featuredDays: featuredDays,
}

export default BooksitoutWebsiteIcon