import React from 'react'
import RouteContainer from "../../../common/styles/RouteContainer";
import useAppUserProfiles from "../useAppUserProfiles";
import ProfileListItemLoading from "./ProfileListItemLoading";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import ProfileListItem from "./ProfileListItem";
import InfiniteScroll from 'react-infinite-scroll-component';
import RouteTitle from "../../../common/RouteTitle/RouteTitle";
import booksitoutIcon from "../../../config/BooksitoutIcon";
import RouteTitleConfig from "../../../config/RouteTitleConfig";

const ProfileUsersListRoute = () => {
    const [appUserWithBooks, isLoading, _, paging] = useAppUserProfiles(20)

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.book/>}
                title={'서재 둘러보기'}
                subTitle={'나만의 서재를 만들어 가고 다른 사람의 서재를 구경해 보세요'}
                currentKey={'index'}
                buttons={RouteTitleConfig.Book}
                rightUi={undefined}
            />

            <RowSpacer/>

            {
                isLoading ?
                    Array.from({length: 10}).map(_ => <ProfileListItemLoading/>)
                    :
                    <InfiniteScroll
                        loader={
                            <CardBodyContentContainer>
                                <ProfileListItemLoading/>
                            </CardBodyContentContainer>
                        }
                        next={paging.fetchNext}
                        hasMore={paging.hasMore}
                        dataLength={appUserWithBooks.length}
                        className='overflow-hidden'
                    >
                        {
                            appUserWithBooks.map(appUserWithBook =>
                                <div className={'ps-1 pe-1'}>
                                    <ProfileListItem appUserWithBooks={appUserWithBook}/>
                                    <RowSpacer size={10}/>
                                </div>
                            )
                        }
                    </InfiniteScroll>
            }
            <RowSpacer/>
        </RouteContainer>
    )
}
export default ProfileUsersListRoute
