import React from 'react'
import styled from 'styled-components';
import {AiOutlineArrowRight as RightArrowIcon} from 'react-icons/ai'
import {Card} from 'react-bootstrap'
import breakpoints from '../config/Breakpoints';
import BooksitoutProductType from "./type/BooksitoutProductType";
import parse from 'html-react-parser'
import RowSpacer from "../common/styles/RowSpacer";
import BooksitoutWebsiteLink from './BooksitoutWebsiteLink';
import './hoverEffect.css'
import useLanguage from "../common/language/useLanguage";
import getLanguageValue from "../common/language/LanguageInfo";

interface Props {
    product: BooksitoutProductType
}

const BooksitoutProductCard: React.FC<Props> = ({product}) => {
    const language = useLanguage()

    let MAIN_NAME = getLanguageValue(`Products.${product.id}.name`, language)
    let DESCRIPTION_SHORT = getLanguageValue(`Products.${product.id}.description.short`, language)
    let DESCRIPTION_MEDIUM = getLanguageValue(`Products.${product.id}.description.medium`, language)

    return (
        <CardContainer>
            <CardBody>
                <Row href={`/products/${product.name.url}?language=${language}`}>
                    <div className='col-12 col-xs-3 col-sm-4 col-md-3'>
                        <Image src={product.icon} alt=''/>
                        <MainName>{MAIN_NAME}</MainName>

                        {
                            language != 'ENGLISH' &&
                            <EnglishName>{product.name.english}</EnglishName>
                        }
                    </div>

                    <div className='col-12 col-xs-9 col-sm-8 col-md-9 mt-2 mt-sm-0'>
                        <Description>{DESCRIPTION_SHORT}</Description>
                        <DescriptionDetail>{parse(DESCRIPTION_MEDIUM)}</DescriptionDetail>

                        <RowSpacer/>

                        <WebsiteLinkContainer>
                            {
                                product.link.map(link =>
                                    <BooksitoutWebsiteLink link={link.link} icon={link.icon} border={link.border}/>
                                )
                            }
                        </WebsiteLinkContainer>
                    </div>

                    <RightArrowContainer>
                        <RightArrow/>
                    </RightArrowContainer>
                </Row>
            </CardBody>
        </CardContainer>
    )
}

const CardContainer = styled(Card).attrs({
    className: `hover-effect border`,
})`
    margin: 0;
    padding: 0;
    min-height: 250px;
`

const CardBody = styled(Card.Body)`
    width: 100%;
    height: 100%;

    margin: 0;
`

const Row = styled.a.attrs({
    className: `row pt-4`,
})`
    height: 100%;
    align-items: center;
    text-decoration: none;
    color: black;

    &:hover {
        color: black;
    }
`

const Image = styled.img.attrs({
    className: `img-fluid rounded`,
})`
    width: 100px;
    height: 100px;
`

const MainName = styled.div.attrs({
    className: `force-1-line`
})`
    margin-top: 10px;
    font-size: 23px;
`;

const EnglishName = styled.div.attrs({
    className: 'text-secondary',
})`
    font-size: 15px
`

const Description = styled.div`
    font-size: 20px;
    font-weight: bold;

    @media screen and (min-width: ${breakpoints.md}) {
        font-size: 25px;
    }
`

const DescriptionDetail = styled.div.attrs({
    className: `text-secondary`,
})`
    font-size: 15px;
`

const WebsiteLinkContainer = styled.div`
    display: flex;
    justify-content: center;

    margin-right: 10px;
    margin-left: 10px;
`

const RightArrowContainer = styled.div`
    justify-content: right;
    position: absolute;
    right: 10px;

    display: none;
    @media screen and (min-width: ${breakpoints.md}) {
        display: flex;
    }
`

const RightArrow = styled(RightArrowIcon).attrs({
    className: `h1`,
})``

export default BooksitoutProductCard