import {useEffect, useState} from "react";
import BookIsbnResponse from "./BookIsbnResponse";
import {BooksitoutServer} from "../../../config/BooksitoutServer";

const useBookIsbn = (isbn: string | null) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)

    const [book, setBook] = useState<BookIsbnResponse | null>(null)

    useEffect(() => {
        if (isbn == null) {
            setIsError(true)
            setIsLoading(false)
        } else {
            BooksitoutServer
                .get(`/v1/book-isbn/${isbn}`)
                .then(res => setBook(res.data))
                .catch(() => setIsError(true))
                .finally(() => setIsLoading(false));
        }
    }, [isbn]);

    return [book, isLoading, isError] as const
}

export default useBookIsbn