import React, {useState} from 'react'
import styled from 'styled-components';
import BookReadingSessionResponse from "../../reading/BookReadingSessionResponse";
import Modal from "../../../../common/Modal";
import useBookDetailStore from "../useBookDetailStore";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";
import {useParams} from 'react-router-dom';
import toast from "react-hot-toast";
import {Button as BootstrapButton, Form} from "react-bootstrap";
import RowSpacer from "../../../../common/styles/RowSpacer";
import NumberInput from "../../../../common/form/NumberInput";

interface Props {
    isOpen: boolean
    close: () => void
    readingSession: BookReadingSessionResponse
}

const BookDetailReadingSessionEditDeleteModal: React.FC<Props> = ({isOpen, close, readingSession}) => {
    const {bookId} = useParams()
    const {editReadingSession, deleteReadingSession} = useBookDetailStore()

    const [startPage, setStartPage] = useState<string>(readingSession.startPage?.toString() ?? '')
    const [endPage, setEndPage] = useState<string>(readingSession.endPage?.toString() ?? '')
    const [readTimeInMinutes, setReadTimeInMinutes] = useState<string>(readingSession.readTimeInMinutes?.toString() ?? '')

    const [year, setYear] = useState<number>(readingSession.startTime?.getFullYear ?? new Date().getFullYear())
    const [month, setMonth] = useState<number>(readingSession.startTime?.getMonth ?? new Date().getMonth() + 1)
    const [day, setDay] = useState<number>(readingSession.startTime?.getDate ?? new Date().getDate())

    const editReadingSessionToServer = () => {
        const isManuallyAdded = readingSession.isAddedManually

        const request = {
            startPage: Number(startPage),
            endPage: Number(endPage),
            readTimeInMinutes: readTimeInMinutes,

            // For Manual
            year: isManuallyAdded ? year : null,
            month: isManuallyAdded ? month : null,
            day: isManuallyAdded ? day : null,
        }

        BooksitoutServer
            .put(`/v1/book/${bookId}/reading-sessions/${readingSession.id}`, request)
            .then((res) => {
                toast.success(`독서활동을 수정했어요.`)
                editReadingSession(res.data)
                close()
            })
            .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해 주세요.`))
    }

    const deleteReadingSessionToServer = () => {
        if (!window.confirm('정말로 독서활동을 삭제할까요?')) return

        BooksitoutServer
            .delete(`/v1/book/${bookId}/reading-sessions/${readingSession.id}`)
            .then(() => {
                toast.success(`독서활동을 삭제했어요.`)
                deleteReadingSession(readingSession.id)
                close()
            })
            .catch(() => toast.error(`오류가 났어요. 잠시 후 다시 시도해`))
    }

    return (
        <Modal
            titleText={'독서활동 수정 / 삭제하기'}
            isShowing={isOpen}
            onClose={close}
            size={'lg'}
            body={
                <Form onSubmit={editReadingSessionToServer}>
                    <Form.Group>
                        <Form.Label>시작 / 끝 페이지</Form.Label>

                        <Row>
                            <Col6>
                                <NumberInput
                                    placeholder='시작 페이지'
                                    value={startPage}
                                    onChange={(e) => setStartPage(e.target.value)}
                                />
                            </Col6>

                            <Col6>
                                <NumberInput
                                    placeholder='시작 페이지'
                                    value={endPage}
                                    onChange={(e) => setEndPage(e.target.value)}
                                />
                            </Col6>
                        </Row>
                    </Form.Group>
                    <RowSpacer size={10}/>

                    <Form.Group>
                        <Form.Label>독서시간</Form.Label>
                        <NumberInput
                            placeholder={'독서시간 (분)'}
                            value={readTimeInMinutes}
                            onChange={(e) => setReadTimeInMinutes(e.target.value)}
                        />
                    </Form.Group>
                    <RowSpacer size={10}/>

                    {readingSession.isAddedManually &&
                        <>
                            <Form.Group>
                                <Form.Label>날짜</Form.Label>

                                <Row>
                                    <Col4>
                                        <Form.Select onChange={(e) => setYear(Number(e.target.value))}
                                                     value={year}>
                                            {Array.from({length: 10}, (_, i) => {
                                                const year = new Date().getFullYear() - i
                                                return <option key={year} value={year}>{year}년</option>
                                            })}
                                        </Form.Select>
                                    </Col4>

                                    <Col4>
                                        <Form.Select onChange={(e) => setMonth(Number(e.target.value))}
                                                     value={month}>
                                            {Array.from({length: 12}, (_, i) => {
                                                const month = i + 1
                                                return <option key={month}
                                                               value={month}>{month}월</option>
                                            })}
                                        </Form.Select>
                                    </Col4>

                                    <Col4>
                                        <Form.Select onChange={(e) => setDay(Number(e.target.value))}
                                                     value={day}>
                                            {Array.from({length: getDaysInMonth(year, month)}, (_, i) => {
                                                const day = i + 1
                                                return <option key={day} value={day}>{day}일</option>
                                            })}
                                        </Form.Select>
                                    </Col4>
                                </Row>
                            </Form.Group>
                            <RowSpacer size={20}/>
                        </>
                    }

                    <Row>
                        <Col6>
                            {/*@ts-ignore*/}
                            <Button variant={'book-danger'} onClick={deleteReadingSessionToServer}>삭제하기</Button>
                        </Col6>

                        <Col6>
                            <Button variant={'book'} onClick={editReadingSessionToServer}>수정하기</Button>
                        </Col6>
                    </Row>
                </Form>
            }
        />
    )
}

function getDaysInMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
}

const Row = styled.div.attrs({
    className: 'row'
})``

const Col6 = styled.div.attrs({
    className: 'col-12 col-md-6'
})``

const Col4 = styled.div.attrs({
    className: 'col-12 col-md-4'
})``

const Button = styled(BootstrapButton)`
    width: 100%;
`

export default BookDetailReadingSessionEditDeleteModal
