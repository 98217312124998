import React from 'react'
import styled from "styled-components";
import {Button as BootstrapButton} from "react-bootstrap";
import booksitoutIcon from "../../config/BooksitoutIcon";

interface Props {
    url: string
}

const EditButton: React.FC<Props> = ({url}) => {
    return (
        <a href={url}>
            {/*@ts-ignore*/}
            <ButtonContainer variant={'warning'}>
                <Button/>
            </ButtonContainer>
        </a>
    )
}

const ButtonContainer = styled(BootstrapButton)`
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 0;
`;

const Button = styled(booksitoutIcon.edit)`
    padding: 0;

    position: relative;
    bottom: 2px;
    left: 0.25px;
`;

export default EditButton
