import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {BooksitoutLanguage} from "./BooksitoutLanguage";

const useLanguage = () => {
    const location = useLocation();
    const [urlLanguage, setUrlLanguage] = useState<BooksitoutLanguage | null>(null)
    const [browserLanguage, setBrowserLanguage] = useState<BooksitoutLanguage | null>(null)

    useEffect(() => {
        let rawLanguage: BooksitoutLanguage | null = null
        try {
            // @ts-ignore
            rawLanguage = location.search
                .replace("?", "")
                .split("&")
                .map(q => q.split("="))
                .find(keyValue => keyValue[0].toUpperCase() == 'LANGUAGE')[1] ?? 'ENGLISH'
        } catch (e) {}
        if (rawLanguage?.toUpperCase() == 'ENGLISH') {
            setUrlLanguage('ENGLISH')
        } else if (rawLanguage?.toUpperCase() == 'KOREAN') {
            setUrlLanguage('KOREAN')
        } else if (rawLanguage?.toUpperCase() == 'JAPANESE') {
            setUrlLanguage('JAPANESE')
        }

        const browserLanguage = navigator.language || navigator.languages[0]
        const langPrefix = browserLanguage.slice(0, 2)
        switch (langPrefix) {
            case 'ja':
                setBrowserLanguage('JAPANESE')
                break
            case 'en':
                setBrowserLanguage('ENGLISH')
                break
            case 'ko':
                setBrowserLanguage('KOREAN')
                break
            case 'zh':
                break
        }
    }, [])

    return urlLanguage ?? browserLanguage ?? 'ENGLISH'
}

export default useLanguage