import React from 'react'
import {Card} from 'react-bootstrap'
import styled from 'styled-components';
import BooksitoutProductType from "../type/BooksitoutProductType";
import {BooksitoutCard, BooksitoutCardTitle} from '../BooksitoutCard';
import useLanguage from "../../common/language/useLanguage";
import getLanguageValue from "../../common/language/LanguageInfo";

interface Props {
    product: BooksitoutProductType
}

const ProductDetailDemoCard: React.FC<Props> = ({product}) => {
    const language = useLanguage()

    let TITLE = getLanguageValue(`ProductDetailDemoCard.Title`, language)

    return (
        <BooksitoutCard>
            <Card.Body>
                <BooksitoutCardTitle>{TITLE}</BooksitoutCardTitle>

                <div className='row justify-content-center'>
                    {
                        product.demoImages.map(image =>
                            <div className='col-12 col-md-8'>
                                <Image src={image} alt='' className='rounded'/>
                            </div>
                        )
                    }
                </div>
            </Card.Body>
        </BooksitoutCard>
    )
}

const Image = styled.img.attrs({
    className: 'img-fluid',
})``

export default ProductDetailDemoCard