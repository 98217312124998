import BooksitoutWebsiteIcon from "../icons/BooksitoutWebsiteIcon";
import BooksitoutDemoImage from "../demos/BooksitoutDemoImage";
import BooksitoutProductType from "../../../booksitout/type/BooksitoutProductType";
import BooksitoutProductStatus from "../../../booksitout/type/BooksitoutProductStatus";

const booksitoutProductPianoman: BooksitoutProductType = {
	id: 'pianoMan',
	status: BooksitoutProductStatus.STARTED,
	name: {
		english: 'Pianoman',
		url: 'piano-man',
	},
	icon: BooksitoutWebsiteIcon.pianoman,
	link: [
		{
			link: 'https://apps.apple.com/us/app/pianoman/id6456449788',
			icon: BooksitoutWebsiteIcon.appleAppstore,
			border: false,
		},
	],
	thumbnailImage: BooksitoutDemoImage.pianoman,
	detailImage: BooksitoutDemoImage.pianoman,
	featuresCount: 0,
	demoImages: [],
	privacyPolicyDates: [],
}

export default booksitoutProductPianoman