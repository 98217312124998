import React from 'react'
import styled from 'styled-components';
import RowSpacer from "../common/styles/RowSpacer";
import RouteContainer from "../common/styles/RouteContainer";
import CardBodyContentContainer from "../common/styles/CardBodyContentContainer";
import useLanguage from "../common/language/useLanguage";
import getLanguageValue from "../common/language/LanguageInfo";
import parse from 'html-react-parser'

const BooksitoutValuesRoute = () => {
    const language = useLanguage()
    let TITLE = getLanguageValue('BooksitoutValuesRoute.RouteTitle', language)
    let SUB_TITLE = getLanguageValue('BooksitoutValuesRoute.RouteSubTitle', language)

    let VALUE_TITLE1 = getLanguageValue('BooksitoutValuesRoute.ValueTitle.1', language)
    let VALUE_DESCRIPTION1 = getLanguageValue('BooksitoutValuesRoute.ValueDescription.1', language)

    let VALUE_TITLE2 = getLanguageValue('BooksitoutValuesRoute.ValueTitle.2', language)
    let VALUE_DESCRIPTION2 = getLanguageValue('BooksitoutValuesRoute.ValueDescription.2', language)

    let VALUE_TITLE3 = getLanguageValue('BooksitoutValuesRoute.ValueTitle.3', language)
    let VALUE_DESCRIPTION3 = getLanguageValue('BooksitoutValuesRoute.ValueDescription.3', language)

    return (
        <RouteContainer>
            <RowSpacer/>
            <RouteTitle>{TITLE}</RouteTitle>
            <RouteSubTitle>{SUB_TITLE}</RouteSubTitle>

            <RowSpacer/>
            <CardBodyContentContainer>
                <ValueTitle>{VALUE_TITLE1}</ValueTitle>
                <ValueDescription>{parse(VALUE_DESCRIPTION1)}</ValueDescription>
            </CardBodyContentContainer>

            <RowSpacer/>
            <CardBodyContentContainer>
                <ValueTitle>{VALUE_TITLE2}</ValueTitle>
                <ValueDescription>{parse(VALUE_DESCRIPTION2)}</ValueDescription>
            </CardBodyContentContainer>

            <RowSpacer/>
            <CardBodyContentContainer>
                <ValueTitle>{VALUE_TITLE3}</ValueTitle>
                <ValueDescription>{parse(VALUE_DESCRIPTION3)}</ValueDescription>
            </CardBodyContentContainer>

            <RowSpacer size={50}/>
        </RouteContainer>
    )
}

const RouteTitle = styled.h1`
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
`;

const RouteSubTitle = styled.h5.attrs({
    className: 'text-secondary',
})`
    text-align: center;
`

const ValueTitle = styled.h2`
    font-size: 1.4em;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
`

const ValueDescription = styled.p`
    font-size: 1.1em;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: left;
`

export default BooksitoutValuesRoute
