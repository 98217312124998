import {BooksitoutLanguage} from "./BooksitoutLanguage";

interface LanguageInfo {
    korean: string
    english: string
    japanese: string
}

const getLanguageValue = (key: string, language: BooksitoutLanguage): string => {
    const found = LanguageInfoData[key]

    if (!found) {
        return ''
    }

    switch (language) {
        case "ENGLISH":
            return found.english
        case "KOREAN":
            return found.korean
        case "JAPANESE":
            return found.japanese
        default:
            return ''
    }
}

// {ComponentName}.{TagName}
const LanguageInfoData: { [key: string]: LanguageInfo } = {
    "ENGLISH": {
        "korean": "영어",
        "english": "English",
        "japanese": "英語"
    },
    "KOREAN": {
        "korean": "한국어",
        "english": "Korean",
        "japanese": "韓国語"
    },
    "JAPANESE": {
        "korean": "일본어",
        "english": "Japanese",
        "japanese": "日本語"
    },

    // FOOTER
    "Footer.CoreValues": {
        korean: "책잇아웃이 추구하는 가치",
        english: "Booksitout's Core Values",
        japanese: "Booksitoutの価値観"
    },
    "Footer.Services": {
        "korean": "다른 서비스 둘러보기",
        "english": "Explore Other Services",
        "japanese": "他のサービスを見る"
    },
    "Footer.Privacy": {
        "korean": "개인정보처리방침",
        "english": "Privacy Disclaimer",
        "japanese": "プライバシーポリシー"
    },
    "Footer.BusinessRegistrationNumber": {
        "korean": "사업자 등록번호",
        "english": "Korean BRN",
        "japanese": "事業者登録番号"
    },
    "Footer.JinkyumPark": {
        "korean": "대표 박진겸",
        "english": "Jinkyum Park",
        "japanese": "代表 パク・ジンギョム"
    },

    // Products
    "BooksitoutProductsRoute.RouteTitle": {
        korean: "책잇아웃의 제품들",
        english: "Booksitout Products",
        japanese: "ソフトウェア製品"
    },
    "BooksitoutProductsRoute.RouteSubTitle": {
        korean: "실제로 서비스되고 있는 제품들",
        english: "Products that are actually being serviced",
        japanese: "実際にサービスされている製品"
    },
    "BooksitoutProductsDetailRoute.NotFound": {
        "korean": "해당 제품을 찾을 수 없어요.",
        "english": "The product cannot be found .",
        "japanese": "該当の商品が見つかりません。"
    },
    "ProductDetailDescriptionCard.Title": {
        "korean": "소개",
        "english": "Introduction",
        "japanese": "紹介。"
    },
    "ProductDetailFeaturesCard.Title": {
        "korean": "기능",
        "english": "Features",
        "japanese": "機能"
    },
    "ProductDetailDemoCard.Title": {
        "korean": "시연 이미지",
        "english": "Demo Images",
        "japanese": "デモ画像"
    },
    "ProductDetailPrivacyCard.Title": {
        "korean": "개인정보처리방침",
        "english": "Privacy Disclaimer",
        "japanese": "プライバシーポリシー"
    },
    "ProductDetailLanguageCard.Title": {
        "korean": "다른 언어로 보기",
        "english": "View in other languages",
        "japanese": "他の言語で表示"
    },

    "Products.booksitout.name": {
        korean: "책잇아웃",
        english: "Booksitout",
        japanese: "booksitout"
    },
    "Products.booksitout.description.short": {
        korean: "책벌레들의 독서생활을 조금 더 편하게",
        english: "Making book lovers' reading life a bit easier",
        japanese: "読書家の読書生活をもっと快適に"
    },
    "Products.booksitout.description.medium": {
        korean: "독서가들이 독서하면서 불편한 모든 부분을 해결합니다.",
        english: "We solve all the inconveniences that readers face while reading.",
        japanese: "読書家が読書中に感じるすべての不便を解決します。"
    },
    "Products.booksitout.description.long": {
        korean: `
            좋아하는 책을 찾고, 읽고, 기록하고.
            이 모든 과정이 더 편할 수는 없을까요?
            <br/>
            <br/>
            책을 여러군데 찾아 해멜 필요 없이 1번의 검색으로 <b>도서관</b> + <b>구독 서비스</b> + <b>중고서점</b> 모두 찾아보고,
            독서활동을 기록해서 내 독서 통계를 보고, 근처 도서관을 쉽게 찾고 내 회원증을 저장하고, 책에 관해 얘기를 나누고, 내 독서활동을 기반으로 읽을 책을 추천해 주고...
            <br/>
            <br/>
            책을 좋아하는, 좋아하고 싶은 모든 이가 조금 더 편하게 책을 읽을 수 있게, 다양한 기능을 제공하는 독서 종합 플렛폼 책잇아웃입니다.
        `,
        english: `
            Find, read, and record your favorite books.
            Could this whole process be easier?
            <br/>
            <br/>
            With a single search, you can check <b>libraries</b>, <b>subscription services</b>, and <b>used bookstores</b>,
            record your reading activities, view your reading statistics, easily locate nearby libraries, store your library card, discuss books, and get recommendations based on your reading habits...
            <br/>
            <br/>
            Booksitout is a comprehensive reading platform that offers various features to make reading more comfortable for everyone who loves or wants to love books.
        `,
        japanese: `
            好きな本を探して、読んで、記録して。
            このすべてのプロセスをもっと快適にできないでしょうか？
            <br/>
            <br/>
            複数の場所を探し回る必要はなく、1回の検索で<b>図書館</b>＋<b>サブスクリプションサービス</b>＋<b>古本屋</b>をすべてチェックできます。
            読書活動を記録して自分の読書統計を見たり、近くの図書館を簡単に見つけたり、会員証を保存したり、本について話したり、読書活動に基づいて次に読む本をおすすめしてくれます…
            <br/>
            <br/>
            本が好きな人、好きになりたいすべての人が少しでも快適に読書できるよう、さまざまな機能を提供する読書総合プラットフォームです。
        `
    },
    "Products.booksitout.features.1": {
        "korean": "내 책을 등록하고 독서활동을 기록 / 관리",
        "english": "Register my books and record/manage reading activities",
        "japanese": "自分の本を登録して、読書活動を記録・管理"
    },
    "Products.booksitout.features.2": {
        "korean": "내 책과 독서활동에 대한 통계 제공",
        "english": "Provides statistics on my books and reading activities",
        "japanese": "自分の本や読書活動の統計を提供"
    },
    "Products.booksitout.features.3": {
        "korean": "도서관, 중고서점, 구독 등 다양한 곳에서 1번에 책 검색",
        "english": "Search for books in one place from libraries, secondhand bookstores, subscriptions, etc.",
        "japanese": "図書館、中古書店、購読など様々な場所で一度に本を検索"
    },
    "Products.booksitout.features.4": {
        "korean": "내 근처의 도서관을 쉽게 찾아보고 도서관 회원증을 관리",
        "english": "Easily find nearby libraries and manage library cards",
        "japanese": "近くの図書館を簡単に見つけ、図書館カードを管理"
    },
    "Products.booksitout.features.5": {
        "korean": "내 독서 기록을 공유하고 얘기를 나눌 수 있는 커뮤니티",
        "english": "A community to share reading records and discuss",
        "japanese": "読書記録を共有して、話し合うことができるコミュニティ"
    },

    "Products.substrack.name": {
        korean: "고정지출 돌보미",
        english: "Substrack",
        japanese: "固定費マスター"
    },
    "Products.substrack.description.short": {
        korean: "넘쳐나는 고정지출, 쉽게 관리하기",
        english: "Manage your overwhelming recurring expenses easily",
        japanese: "溢れかえる固定支出を簡単に管理"
    },
    "Products.substrack.description.medium": {
        korean: `
            언제 어떤 고정지출들이 나가는지, 헷갈리지 않으세요? 
            <br/>
            고정지출 돌보미가 고정지출을 쉽고 편하게 관리해 드려요.
        `,
        english: `
            Confused about when and which recurring expenses are coming out?
            <br/>
            Substrack makes managing your recurring expenses easy and convenient.
        `,
        japanese: `
            いつ、どの固定費が発生しているか、混乱していませんか？
            <br/>
            固定費マスターが固定費を簡単かつ便利に管理します。
        `
    },
    "Products.substrack.description.long": {
        korean: `
            갑자기 결제된 내 고정지출들, 쉽게 관리하고 싶지 않으세요?
            <br/>
            <br/>
            고정지출 돌보미를 써 고정지출을 1곳에 모아 관리할 수 있어요. 
            <br/>
            <br/>
            결제일 D-Day를 한 눈에 봐서 미리 계획하고, 필요없는 지출을 미리 막을 수 있어요.
            나에게 필요할 지도 모르는 다양한 구독 서비스들을 둘러볼 수 있어요.
            고정지출의 결제 카드를 등록해 관리할 수 있어요.
        `,
        english: `
            Want to easily manage your recurring expenses that seem to pop up out of nowhere?
            <br/>
            <br/>
            With Substrack, you can gather all your recurring expenses in one place for easy management.
            <br/>
            <br/>
            See upcoming payment dates at a glance and plan ahead, or stop unnecessary expenses in advance.
            You can also explore various subscription services that may be useful to you.
            Register your payment card for recurring expenses for better tracking.
        `,
        japanese: `
            突然発生する固定費を簡単に管理したくありませんか？
            <br/>
            <br/>
            固定費マスターを使用して、すべての固定費を1か所に集めて管理できます。
            <br/>
            <br/>
            決済日のD-Dayを一目で確認し、前もって計画し、不要な支出を事前に防ぐことができます。
            自分に必要かもしれないさまざまなサブスクリプションサービスを見つけることもできます。
            固定費の決済カードを登録して、管理を簡単にしましょう。
        `
    },
    "Products.substrack.features.1": {
        "korean": "내 고정 지출들을 등록해 언제 얼마나 결제되는지 관리하기",
        "english": "Register my recurring expenses to manage when and how much is being charged",
        "japanese": "自分の固定支出を登録して、いつどれくらい請求されるか管理"
    },
    "Products.substrack.features.2": {
        "korean": "결제되기 전 알림",
        "english": "Receive notifications before payment",
        "japanese": "支払い前の通知"
    },
    "Products.substrack.features.3": {
        "korean": "새로운 구독 서비스 둘러보기",
        "english": "Explore new subscription services",
        "japanese": "新しいサブスクリプションサービスを探す"
    },

    "Products.purrfitTracker.name": {
        korean: "수입 관리냥",
        english: "Purrfit Tracker",
        japanese: "収入管理ニャン"
    },
    "Products.purrfitTracker.description.short": {
        korean: "내 수입, 쉽게 관리하기",
        english: "Easily manage your income",
        japanese: "収入を簡単に管理"
    },
    "Products.purrfitTracker.description.medium": {
        korean: `
            다양한 곳에서 들어오는 내 수입을 관리하고 기록하고 싶으세요?
            <br/>
            수입을 쉽게 기록하고 통계도 제공받아 보세요!
        `,
        english: `
            Want to manage and record your income from various sources?
            <br/>
            Easily record your income and receive useful statistics!
        `,
        japanese: `
            さまざまな収入源からの収入を管理して記録したいですか？
            <br/>
            収入を簡単に記録し、統計を取得しましょう！
        `
    },
    "Products.purrfitTracker.description.long": {
        korean: `
            "월급만으론 부족하다!"
            <br/>
            다양한 곳에서 들어오는 내 수입들을 편하게 관리하면 좋지 않을까요?
            <br/>
            수입처들을 등록하고 수입을 추가하면 내 수입들을 한 눈에 볼 수 있어요.
            <br/>
            뿐만 아니라 내 수입들을 분석해서 통계로 보여줘요.
            <br/>
            <br/>
            수입 관리냥으로 다양한 곳에서 오는 수입들을 편하게 관리해 보세요!
        `,
        english: `
            "Salary alone isn't enough!"
            <br/>
            Wouldn't it be nice to easily manage all your income from various sources?
            <br/>
            Register your income sources and track your income, seeing everything at a glance.
            <br/>
            Additionally, analyze and view your income as helpful statistics.
            <br/>
            <br/>
            With Purrfit Tracker, easily manage all your income from various places!
        `,
        japanese: `
            「給料だけでは足りない！」
            <br/>
            さまざまな収入源からの収入を簡単に管理できたらよくないですか？
            <br/>
            収入源を登録して収入を追加すれば、すべての収入を一目で確認できます。
            <br/>
            さらに、収入を分析して統計で表示します。
            <br/>
            <br/>
            収入管理ニャンでさまざまな場所からの収入を簡単に管理しましょう！
        `
    },
    "Products.purrfitTracker.features.1": {
        "korean": "내 수입처와 그 수입처에 해당하는 수입을 등록",
        "english": "Register my income sources and the corresponding income",
        "japanese": "自分の収入源とその収入を登録"
    },
    "Products.purrfitTracker.features.2": {
        "korean": "등록된 수입을 바탕으로 통계 제공",
        "english": "Provides statistics based on registered income",
        "japanese": "登録された収入に基づいて統計を提供"
    },
    "Products.purrfitTracker.features.3": {
        "korean": "내 수입 데이터 iCloud에 안전하게 저장",
        "english": "Safely store my income data in iCloud",
        "japanese": "自分の収入データをiCloudに安全に保存"
    },

    "Products.featuredDays.name": {
        korean: "인생의 날",
        english: "Featured Days",
        japanese: "人生の日"
    },
    "Products.featuredDays.description.short": {
        korean: "기억하고 싶은 날들을 기록하세요",
        english: "Record the days you want to remember",
        japanese: "記憶に残したい日を記録しましょう"
    },
    "Products.featuredDays.description.medium": {
        korean: `
            군대에 가기 전, 결혼기념일, 아이의 첫 생일, 정년, 평균 수명까지.
            <br/>
            인생에 중요한 여러 날들을 등록해 관리해 보세요.
        `,
        english: `
            Before military service, wedding anniversaries, your child's first birthday, retirement, and life expectancy.
            <br/>
            Register and manage important days in your life.
        `,
        japanese: `
            軍隊に行く前、結婚記念日、子供の最初の誕生日、退職、平均寿命まで。
            <br/>
            人生で重要な日を登録して管理しましょう。
        `
    },
    "Products.featuredDays.description.long": {
        korean: `
            인생에서 중요한 날을 돌아보며 정리하고 싶을 때가 있습니다.
            <br/>
            며칠, 몇 달이 지났는지 한 눈에 보고, 관련 사진, 메모를 모아둘 수 있습니다.
            <br/>
            위젯, 알림, 즐겨찾기로 쉽게 접근할 수도 있게 해 줍니다.
        `,
        english: `
            There are times when you want to reflect on and organize the important days in your life.
            <br/>
            See how many days or months have passed at a glance, and gather related photos and notes.
            <br/>
            Widgets, notifications, and favorites make it easy to access.
        `,
        japanese: `
            人生で重要な日を振り返り、整理したいときがあります。
            <br/>
            何日、何か月が経ったかを一目で確認でき、関連する写真やメモを集めておくことができます。
            <br/>
            ウィジェット、通知、お気に入りで簡単にアクセスできます。
        `
    },
    "Products.featuredDays.features.1": {
        "korean": "개인정보를 입력해 모든 사람의 인생에 중요한 날들을 자동으로 등록 (특정 나이까지, 정년까지, 평균 수명까지 등)",
        "english": "Automatically register important life dates by entering personal information (up to a certain age, retirement, life expectancy, etc.)",
        "japanese": "個人情報を入力して、すべての人の人生に重要な日付を自動登録（特定の年齢まで、定年まで、平均寿命までなど）"
    },
    "Products.featuredDays.features.2": {
        "korean": "나만의 카테고리를 등록하고 관련 기록을 모아두기 (메모, 사진 등)",
        "english": "Create custom categories and gather related records (notes, photos, etc.)",
        "japanese": "自分だけのカテゴリーを登録して、関連する記録を集める（メモ、写真など）"
    },
    "Products.featuredDays.features.3": {
        "korean": "위젯, 알림, 즐겨찾기로 중요한 날을 놓치지 않기",
        "english": "Never miss important dates with widgets, notifications, and favorites",
        "japanese": "ウィジェット、通知、お気に入りで重要な日を見逃さない"
    },

    // Values
    "BooksitoutValuesRoute.RouteTitle": {
        korean: "책잇아웃이 추구하는 가치",
        english: "Booksitout's Core Values",
        japanese: "Booksitoutの価値観"
    },
    "BooksitoutValuesRoute.RouteSubTitle": {
        korean: "모든 제품을 만들 때 아래의 가치를 철저히 지킵니다.\n",
        english: "We strictly adhere to the following values when creating all our products.",
        japanese: "すべての製品を作る際に、以下の価値観を徹底的に守ります。"
    },

    "BooksitoutValuesRoute.ValueTitle.1": {
        korean: "1. 구독을 요구하지 않고 1회성 결제만 요구합니다.",
        english: "1. We don't require subscriptions, just a one-time payment.",
        japanese: "1. 広告やサブスクリプションは必要なく、1回の支払いだけを求めます。"
    },
    "BooksitoutValuesRoute.ValueDescription.1": {
        korean: `
        광고는 싫어하지만 여러 서비스에 구독료를 내기는 부담스럽지 않으신가요?
        <br/>
        저희도 그렇습니다.
        <br/>
        책잇아웃의 제품은 (결제가 있다면) 1회성 결제로 영원히 제품을 이용할 수 있습니다.
    `,
        english: `
        Don’t like ads but feel burdened by subscription fees for various services?
        <br/>
        We feel the same way.
        <br/>
        With Booksitout, you can use our products forever with a one-time payment.
    `,
        japanese: `
        広告は嫌いだけど、さまざまなサービスにサブスクリプション料金を支払うのは負担ではありませんか？
        <br/>
        私たちも同じ気持ちです。
        <br/>
        Booksitoutの製品は、一度の支払いで永久に利用できます。
    `
    },

    "BooksitoutValuesRoute.ValueTitle.2": {
        korean: `2. 사용자가 편한 제품을 만들 수 있습니다.`,
        english: "2. We can create products that are user-friendly.",
        japanese: "2. 使いやすい製品を作られます。"
    },
    "BooksitoutValuesRoute.ValueDescription.2": {
        korean: `
        \`상품의 대가를 치르지 않는다면 당신이 상품이다.\`
        <br/>
        책잇아웃은 비용을 지불합니다.
        <br/>
        저희의 관심사는 사용자분들이 앱을 편하게 이용해 제품들에 대한 신뢰를 생기게 해 다른 제품도 구매하실 생각이 들게 하는 것입니다.
        <br/>
        불필요한 알림, 이메일, 푸시로 사용자를 괴롭힐 필요가 없습니다.
        <br/>
        광고를 노출시키기 위해 UI를 의도적으로 불편하게 설계할 필요도 없습니다.
    `,
        english: `
        \`If you're not paying for the product, you are the product.\`
        <br/>
        At Booksitout, we make sure the costs are transparent.
        <br/>
        Our goal is to make users feel comfortable using the app, trust our products, and want to buy more.
        <br/>
        There's no need for unnecessary notifications, emails, or push messages to bother users.
        <br/>
        We don't design UI to be intentionally inconvenient just to expose ads.
    `,
        japanese: `
        「製品の対価を支払わないなら、あなたが商品です。」
        <br/>
        Booksitoutはコストを支払います。
        <br/>
        私たちの関心は、ユーザーがアプリを快適に利用し、製品に信頼を持ち、他の製品も購入したいと思うようにすることです。
        <br/>
        不要な通知、メール、プッシュメッセージでユーザーを煩わせる必要はありません。
        <br/>
        広告を見せるためにUIをわざと使いにくく設計する必要もありません。
    `
    },

    "BooksitoutValuesRoute.ValueTitle.3": {
        korean: "3. 많은 제품을 출시하는 대신에, 적은 제품을 끝까지 책임집니다.",
        english: "3. We don’t release many products, so we stand behind every one of them.",
        japanese: "3. 多くの製品を出す代わりに、出した製品には最後まで責任を持ちます。"
    },
    "BooksitoutValuesRoute.ValueDescription.3": {
        korean: `
        사용자분들이 낸 돈을 쉽게 생각하지 않습니다.
        <br/>
        1만원이 안 되는 돈이지만, 책임감을 가지고 낸 제품은 계속 유지보수합니다.
    `,
        english: `
        We don’t take lightly the money our users spend.
        <br/>
        Even if it’s less than 10 bucks, we maintain our products with responsibility.
    `,
        japanese: `
        ユーザーが支払ったお金を軽く考えていません。
        <br/>
        １千円に満たない金額でも、責任を持って製品を維持し続けます。
    `
    }
}

export default getLanguageValue