import React, {useEffect, useState} from 'react'
import RouteContainer from "../../../common/styles/RouteContainer";
import {useParams} from "react-router";
import useAppUserProfile from "../useAppUserProfile";
import RowSpacer from "../../../common/styles/RowSpacer";
import ProfileUserCard from "./ProfileUserCard";
import {Pagination} from 'react-bootstrap'
import Paging from "../../../common/hooks/Paging";
import useUrlQuery from "../../../common/hooks/useUrlQuery";
import BookMineCard from "../../book/mine/BookMineCard";
import styled from "styled-components";

const ProfileBooksRoute = () => {
    const {userId} = useParams();
    const pageQuery = useUrlQuery('page');

    const pageNum = pageQuery !== null && !isNaN(Number(pageQuery)) ? Number(pageQuery) : null

    const [appUserWithBooks, isLoading, isError, paging] = useAppUserProfile(userId, pageNum == null ? null : pageNum - 1, 24);
    const [page, setPage] = useState<number>(pageNum ?? 1);

    useEffect(() => {
        if (page !== pageNum) {
            setPage(pageNum ?? 1)
        }
    }, [pageQuery, pageNum])


    if (appUserWithBooks == null) {
        return <></>
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <ProfileUserCard user={appUserWithBooks.user}/>

            <RowSpacer/>
            <Row>
                {
                    appUserWithBooks.books.map(book =>
                        <Col>
                            <BookMineCard book={book}/>
                        </Col>
                    )
                }
            </Row>

            <Page paged={paging} currentPage={page} url={`/users/${userId}/books`}/>
            <RowSpacer/>
        </RouteContainer>
    )
}

interface PageProps {
    paged: Paging
    currentPage: number
    url: string
}

const Page: React.FC<PageProps> = ({paged, currentPage, url}) => {

    if (paged.totalPages === 0) return <></>

    return (
        <div className='d-flex justify-content-center mb-5 mt-5'>
            <Pagination>
                {paged.totalPages > 10 && <Pagination.First href={`${url}?page=1`}/>}

                {Array.from({length: paged.totalPages}, (_, index) => index + 1).map((p) => {
                    return (
                        <Pagination.Item active={p == currentPage ?? 1} href={`${url}?page=${p}`}>
                            {p}
                        </Pagination.Item>
                    )
                })}

                {paged.totalPages > 10 && <Pagination.Last href={`${url}?page=${paged.totalPages}`}/>}
            </Pagination>
        </div>
    )
}

const Row = styled.div.attrs({
    className: 'row'
})``

const Col = styled.div.attrs({
    className: 'col-6 col-md-4 col-lg-3 col-xl-2 not-clickable'
})``

export default ProfileBooksRoute