import {useEffect, useState} from "react";
import AppUserWithBooksResponse from "./AppUserWithBooksResponse";
import {BooksitoutServer} from "../../config/BooksitoutServer";
import Paging from "../../common/hooks/Paging";

const useAppUserProfiles = (size?: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)

    const [appUserWithBooks, setAppUserWithBooks] = useState<AppUserWithBooksResponse[]>([])

    const [page, setPage] = useState<number>(0)
    const [isLast, setIsLast] = useState<boolean>(false)
    const [totalPages, setTotalPages] = useState<number>(0)

    useEffect(() => {
        BooksitoutServer
            .get(`/v1/users?page=${page}&size=${size ?? 10}`)
            .then(res => {
                setAppUserWithBooks(prev => [...prev, ...res.data.content])
                setIsLast(res.data.isLast)
                setTotalPages(res.data.totalPages)
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false));
    }, [])

    const paging: Paging = {
        hasMore: !isLast,
        totalPages: totalPages,
        fetchNext: () => setPage(page + 1),
    }

    return [appUserWithBooks, isLoading, isError, paging] as const
}

export default useAppUserProfiles
