import React from 'react'
import styled from 'styled-components';
import RouteContainer from "../../../common/styles/RouteContainer";
import {Card, Row} from "react-bootstrap";
import RowSpacer from "../../../common/styles/RowSpacer";
import RouteTitle from "../../../common/RouteTitle/RouteTitle";
import booksitoutIcon from '../../../config/BooksitoutIcon';
import {BsFillArrowRightCircleFill as RightArrowIcon} from 'react-icons/bs'
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RouteTitleConfig from "../../../config/RouteTitleConfig";

const SettingsRoute = () => {
    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.settings/>}
                title={'설정'}
                currentKey={'main'}
                buttons={RouteTitleConfig.Settings}
            />

            <RowSpacer/>
            <Row>
                <Col>
                    <SettingsCard title={'개인정보 설정'} contents={['카카오, 네이버, 구글, 페이스북 계정 추가']}
                                  link={`/settings/personal-infos`}/>
                </Col>

                <Col>
                    <SettingsCard title={'내 프로필 설정'} contents={['프로필 사진 변경', '닉네임 변경']} link={`/settings/profiles`}/>
                </Col>
            </Row>

            <RowSpacer/>
        </RouteContainer>
    )
}

interface Props {
    title: string
    contents: string[]
    link: string
}

const SettingsCard: React.FC<Props> = ({title, contents, link}) => {
    return (
        <Card className='mb-4'>
            <CardBodyContentContainer style={{minHeight: '300px'}}>
                <a href={link}>
                    <div className='row row-eq-height'>
                        <div className='col-10'>
                            <h3 className='mb-4'>{title}</h3>
                            <div className='ms-4 text-secondary'>
                                {contents.map(content => <p className='mb-2'>{content}</p>)}
                            </div>
                        </div>

                        <div className='col-2' style={{minHeight: '260px'}}>
                            <div className='d-flex justify-content-end align-items-center h-100'>
                                <RightArrowIcon className='text-book' size={30}/>
                            </div>
                        </div>
                    </div>
                </a>
            </CardBodyContentContainer>
        </Card>
    )
}

const Col = styled.div.attrs({
    className: 'col-12 col-md-6 hover-effect'
})``

export default SettingsRoute
