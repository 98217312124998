import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import {useParams} from "react-router";
import Modal from "../../../../common/Modal";
import {Form} from "react-bootstrap";
import RowSpacer from "../../../../common/styles/RowSpacer";
import AddButton from "../../../../common/button/AddButton";
import {BooksitoutServer} from "../../../../config/BooksitoutServer";
import toast from "react-hot-toast";
import useBookDetailStore from "../useBookDetailStore";
import NumberInput from "../../../../common/form/NumberInput";

interface Props {
    isOpen: boolean
    close: () => void
}

const BookDetailReadingSessionAddModal: React.FC<Props> = ({isOpen, close}) => {
    const {bookId} = useParams()
    const {book, addReadingSession} = useBookDetailStore()

    useEffect(() => {
        setStartPage(book?.currentPage?.toString() ?? null)
    }, [book]);

    const [startPage, setStartPage] = useState<string | null>(null)
    const [endPage, setEndPage] = useState<string | null>(null)
    const [readTimeInMinutes, setReadTimeInMinutes] = useState<number | null>(null)
    const [year, setYear] = useState<number>(new Date().getFullYear())
    const [month, setMonth] = useState<number>(new Date().getMonth() + 1)
    const [day, setDay] = useState<number>(new Date().getDate())

    const addReadingSessionToServer = () => {
        const data = {
            startPage: startPage,
            endPage: endPage,
            readTimeInMinutes: readTimeInMinutes,
            year: year,
            month: month,
            day: day
        }

        BooksitoutServer
            .post(`/v1/book/${bookId}/reading-sessions`, data)
            .then((res) => {
                toast.success('독서활동을 추가했어요.')
                addReadingSession(res.data)
                close()
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요.'))
    }

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        addReadingSessionToServer()
    }

    return (
        <Modal
            isShowing={isOpen}
            onClose={close}
            titleText={'독서활동 추가하기'}
            size={'lg'}
            body={
                <Form onSubmit={handleOnSubmit}>
                    <Form.Group>
                        <Form.Label>시작 / 끝 페이지</Form.Label>

                        <Row>
                            <Col6>
                                <NumberInput
                                    placeholder='시작 페이지'
                                    value={startPage}
                                    onChange={(e) => setStartPage(e.target.value)}
                                />
                            </Col6>

                            <Col6>
                                <NumberInput
                                    placeholder='끝 페이지'
                                    onChange={(e) => setEndPage(e.target.value)}
                                />
                            </Col6>
                        </Row>
                    </Form.Group>
                    <RowSpacer size={10}/>

                    <Form.Group>
                        <Form.Label>독서시간</Form.Label>
                        <NumberInput
                            placeholder={'독서시간 (분)'}
                            onChange={(e) => setReadTimeInMinutes(Number(e.target.value))}
                        />
                    </Form.Group>
                    <RowSpacer size={10}/>

                    <Form.Group>
                        <Form.Label>날짜</Form.Label>

                        <Row>
                            <Col4>
                                <Form.Select onChange={(e) => setYear(Number(e.target.value))} value={year}>
                                    {Array.from({length: 10}, (_, i) => {
                                        const year = new Date().getFullYear() - i
                                        return <option key={year} value={year}>{year}년</option>
                                    })}
                                </Form.Select>
                            </Col4>

                            <Col4>
                                <Form.Select onChange={(e) => setMonth(Number(e.target.value))} value={month}>
                                    {Array.from({length: 12}, (_, i) => {
                                        const month = i + 1
                                        return <option key={month} value={month}>{month}월</option>
                                    })}
                                </Form.Select>
                            </Col4>

                            <Col4>
                                <Form.Select onChange={(e) => setDay(Number(e.target.value))} value={day}>
                                    {Array.from({length: getDaysInMonth(year, month)}, (_, i) => {
                                        const day = i + 1
                                        return <option key={day} value={day}>{day}일</option>
                                    })}
                                </Form.Select>
                            </Col4>
                        </Row>
                    </Form.Group>
                    <RowSpacer size={20}/>

                    <AddButton label={'추가하기'} onClick={addReadingSessionToServer}/>
                </Form>
            }
        />
    )
}

function getDaysInMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
}

const Row = styled.div.attrs({
    className: 'row'
})``

const Col6 = styled.div.attrs({
    className: 'col-12 col-md-6'
})``

const Col4 = styled.div.attrs({
    className: 'col-12 col-md-4'
})``

export default BookDetailReadingSessionAddModal
