import BooksitoutWebsiteIcon from "../icons/BooksitoutWebsiteIcon";
import BooksitoutDemoImage from "../demos/BooksitoutDemoImage";
import BooksitoutProductType from "../../../booksitout/type/BooksitoutProductType";
import BooksitoutProductStatus from "../../../booksitout/type/BooksitoutProductStatus";

const BooksitoutProductBooksitout: BooksitoutProductType = {
	id: 'booksitout',
	status: BooksitoutProductStatus.PRODUCTION,
	name: {
		english: 'booksitout',
		url: 'booksitout',
	},
	icon: BooksitoutWebsiteIcon.booksitout,
	link: [
		{
			link: 'https://booksitout.com',
			icon: BooksitoutWebsiteIcon.booksitout,
			border: false,
		},
	],
	featuresCount: 5,
	demoImages: [],
	thumbnailImage: BooksitoutDemoImage.booksitout,
	detailImage: BooksitoutDemoImage.booksitout,
	privacyPolicyDates: ['2023-07-01']
}

export default BooksitoutProductBooksitout