import {useEffect, useState} from "react"
import BookGoalResponse from "./BookGoalResponse"
import {BooksitoutServer} from "../../../config/BooksitoutServer"
import ApiUrls from "../../../ApiUrls"

const useBookGoal = (year: number) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [goal, setGoal] = useState<BookGoalResponse | null>(null)
    const [isDone, setIsDone] = useState<boolean>(false)

    useEffect(() => {
        BooksitoutServer
            .get(ApiUrls.Book.Goal.GET(year))
            .then((res) => {
                if (res.status != 204) {
                    setGoal(res.data)
                }

                setIsDone(res.data.current >= res.data.total && res.data.total !== 0)
            })
            .finally(() => setIsLoading(false))
    }, [year])

    return [goal, isDone, isLoading] as const
}

export default useBookGoal