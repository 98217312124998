import React, {useEffect, useState} from 'react'
import styled from 'styled-components';

import 'draft-js/dist/Draft.css'
import {
    Editor,
    EditorState,
    RichUtils,
    Modifier,
    convertToRaw,
    DefaultDraftBlockRenderMap,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import {stateFromHTML} from 'draft-js-import-html';
import {Map} from 'immutable';

import './TextForm.scss'

import TextFormKeyBindingFn from './TextFormKeyBindingFn';
import TextFormEditorButton from './TextFormEditorButton'
import TextFormButton from './TextFormButton';
import TextFormBlockStyleFn from "./TextFormBlockStyleFn";

interface Props {
    placeholder: string,
    initialContent?: string,
    setContent: (content: string) => void,
    height?: number,
    autoFocus?: boolean,
}

const TextForm: React.FC<Props> = ({
                                       placeholder,
                                       initialContent,
                                       setContent,
                                       height = 300,
                                       autoFocus = true,
                                   }) => {
    const customStyleMap = {
        highlight: {
            backgroundColor: '#ECECEA',
            color: '#CE5858',
            padding: '2.5px',
            margin: '2.5px',
            borderRadius: '4px',
        },
    }

    const customBlockRenderMap = Map({
        'unordered-list-item': {
            element: 'li',
            wrapper: <ul className="editor-ul"/>
        },
        'ordered-list-item': {
            element: 'li',
            wrapper: <ol className="editor-ol"/>
        },
    });

    const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(customBlockRenderMap);

    const editor = React.useRef<Editor>(null)
    const processedInitialContent = initialContent?.replaceAll('\n', '<br/>');

    const [editorState, setEditorState] = useState(() =>
        processedInitialContent
            ? EditorState.createWithContent(stateFromHTML(processedInitialContent))
            : EditorState.createEmpty()
    );
    const [showPlaceholder, setShowPlaceholder] = React.useState<boolean>(true)

    const onChange = (newEditorState: EditorState) => {
        const selection = newEditorState.getSelection();
        const currentContent = newEditorState.getCurrentContent();
        const key = selection.getStartKey();
        const block = currentContent.getBlockForKey(key);
        const text = block.getText();
        const blockType = block.getType();

        // Check if the block starts with '- ' and handle both the block type change and text update at once
        if (text.startsWith('- ')) {
            let newContentState = currentContent;

            // Set the block type to 'unordered-list-item' if not already and remove '- ' from the start
            if (blockType !== 'unordered-list-item' || text.startsWith('- ')) {
                // Set the block type first if it's not already an unordered-list-item
                if (blockType !== 'unordered-list-item') {
                    newContentState = Modifier.setBlockType(
                        newContentState,
                        selection,
                        'unordered-list-item'
                    );
                }

                // Then, remove '- ' from the text
                const newText = text.slice(2); // Remove the '- '
                newContentState = Modifier.replaceText(
                    newContentState,
                    selection.merge({
                        anchorOffset: 0,
                        focusOffset: 2
                    }),
                    newText
                );

                // Push the updated state to the editor
                const newState = EditorState.push(newEditorState, newContentState, 'change-block-data');
                setEditorState(newState);
                return;
            }
        }

        // If no conditions are met, maintain the current state
        setEditorState(newEditorState);
    }

    useEffect(() => {
        if (editor.current && autoFocus) {
            editor.current.focus()
        }
    }, [autoFocus])

    useEffect(() => {
        const options = {
            inlineStyles: {
                // Define custom style mappings here
                'highlight': {
                    style: {backgroundColor: '#ECECEA', color: '#CE5858'},
                    element: 'span',
                    attributes: {class: 'highlight'}
                },
            },
        }

        setShowPlaceholder(
            editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() !== 'unordered-list-item'
        )

        // const textInHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        // setContent(textInHtml)

        const textInHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()), options);
        setContent(textInHtml);
    }, [editorState, setContent])

    const toggleTextStyle = (style: string) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style))
    }

    const toggleBlockStyle = (blockType: string) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType))
    }

    const handleReturn = (event: any, editorState: EditorState) => {
        const contentState = editorState.getCurrentContent()
        const selectionState = editorState.getSelection()
        const currentBlock = contentState.getBlockForKey(selectionState.getStartKey())

        // if (currentBlock.getText().trim() === '') {
        // const newContentState = RichUtils.tryToRemoveBlockStyle(editorState, selectionState)
        // const newEditorState = EditorState.push(editorState, newContentState, 'change-block-type')
        // setEditorState(newEditorState)
        // return 'handled'
        // }

        return 'not-handled'
    }

    const handleShortcut = (command: string, editorState: EditorState) => {
        if (command === 'highlight') {
            toggleTextStyle(command)
            return 'handled'
        }

        const newState = RichUtils.handleKeyCommand(editorState, command)
        if (newState) {
            onChange(newState)
            return 'handled'
        }

        return 'not-handled'
    }

    const active = {
        quote: () => editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'blockquote',
        list: () => editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType() === 'unordered-list-item',
        bold: () => editorState.getCurrentInlineStyle().has('BOLD'),
        underline: () => editorState.getCurrentInlineStyle().has('UNDERLINE'),
        italic: () => editorState.getCurrentInlineStyle().has('ITALIC'),
        highlight: () => editorState.getCurrentInlineStyle().has('highlight'),
    }

    const [labelLanguage, setLabelLanguage] = React.useState<'english' | 'korean'>('korean')

    const label = {
        korean: {
            quote: '인용',
            list: '리스트',
            bold: '굵게',
            italic: '기울게',
            underline: '밑줄',
            highlight: '강조',
        },
        english: {
            quote: 'Quote',
            list: 'List',
            bold: 'B',
            italic: 'I',
            underline: 'U',
            highlight: 'H',
        },
    }

    const focusEditor = () => {
        if (editor && editor.current) {
            editor.current.focus()
        }
    }

    return (
        <EditorFormContainer height={height} onClick={focusEditor} className="border rounded p-2">
            <TextFormButton labelLanguage={labelLanguage} setLabelLanguage={setLabelLanguage}/>

            <BlockButtonContainer>
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? label.english.quote : label.korean.quote}
                    style="blockquote"
                    active={active.quote()}
                    onToggleStyle={toggleBlockStyle}
                />
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? label.english.list : label.korean.list}
                    style="unordered-list-item"
                    active={active.list()}
                    onToggleStyle={toggleBlockStyle}
                />
            </BlockButtonContainer>

            <div className="mb-2"/>

            <TextButtonContainer>
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? label.english.bold : label.korean.bold}
                    style="BOLD"
                    active={active.bold()}
                    onToggleStyle={toggleTextStyle}
                />
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? label.english.italic : label.korean.italic}
                    style="ITALIC"
                    active={active.italic()}
                    onToggleStyle={toggleTextStyle}
                />
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? label.english.underline : label.korean.underline}
                    style="UNDERLINE"
                    active={active.underline()}
                    onToggleStyle={toggleTextStyle}
                />
                <TextFormEditorButton
                    label={labelLanguage === 'english' ? label.english.highlight : label.korean.highlight}
                    style="highlight"
                    active={active.highlight()}
                    onToggleStyle={toggleTextStyle}
                />
            </TextButtonContainer>

            <hr className="m-3"/>

            <EditorContainer height={height}>
                {/* @ts-ignore */}
                <Editor
                    ref={editor}
                    placeholder={showPlaceholder && placeholder}
                    onChange={onChange}

                    editorState={editorState}
                    handleReturn={handleReturn}
                    handleKeyCommand={handleShortcut}

                    customStyleMap={customStyleMap}
                    blockRenderMap={extendedBlockRenderMap}

                    keyBindingFn={TextFormKeyBindingFn}
                    blockStyleFn={TextFormBlockStyleFn}
                />
            </EditorContainer>
        </EditorFormContainer>
    )
}

const EditorContainer = styled.div<EditorContainerProps>`
    padding-left: 20px;
    padding-right: 20px;
    overflow: scroll;
    height: ${props => props.height}px;
`

const EditorFormContainer = styled.div<EditorContainerProps>`
    min-height: ${props => props.height}px;
    background-color: white;
    overflow-y: scroll;
`;

interface EditorContainerProps {
    height: number
}

const TextButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    height: 100%;
`

const BlockButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`

export default TextForm