import React, {useEffect, useState} from 'react'
import styled from 'styled-components';
import RouteTitle from "../../../common/RouteTitle/RouteTitle";
import booksitoutIcon from "../../../config/BooksitoutIcon";
import RouteTitleConfig from "../../../config/RouteTitleConfig";
import RowSpacer from "../../../common/styles/RowSpacer";
import RouteContainer from "../../../common/styles/RouteContainer";
import {useNavigate} from "react-router-dom";
import useLoginStore from "../../login/useLoginStore";
import ProfileUserCard from "../profiles/ProfileUserCard";
import {BooksitoutServer} from "../../../config/BooksitoutServer";
import AppUserResponse from "../AppUserResponse";
import NoContent from "../../../common/NoContent";
import {useDropzone} from "react-dropzone"
import {Button, Card, Form} from "react-bootstrap";
import toast from "react-hot-toast";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import {BsFillImageFill as ImageIcon} from 'react-icons/bs'

const SettingsProfileRoute = () => {
    const navigate = useNavigate()
    const {isLoggedIn} = useLoginStore()

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)

    const [appUser, setAppUser] = useState<AppUserResponse | null>(null)
    const [name, setName] = useState('')
    const [isNameUnique, setIsNameUnique] = useState<boolean>(false)
    const [profileImage, setProfileImage] = useState<string | null>(null)

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate(`/`)
        }

        BooksitoutServer
            .get(`/v1/users/settings/profile`)
            .then(res => {
                setAppUser(res.data)
                setName(res.data.name)
            })
            .catch(() => setIsError(true))
            .finally(() => setIsLoading(false))
    }, [])

    const [imageData, setImageData] = useState<File | null>(null)
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        multiple: false,
        onDrop: React.useCallback((image) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const buffer = reader.result as ArrayBuffer
                const blob = new Blob([buffer])
                const imageUrl = URL.createObjectURL(blob);

                setProfileImage(imageUrl)
                setImageData(image[0])
                // @ts-ignore
                if (appUser != null) {
                    setAppUser({id: appUser.id, name: appUser?.name, profileImageUrl: imageUrl, registeredDate: appUser?.registeredDate});
                }
            }

            reader.readAsArrayBuffer(image[0])
        }, []),
    })

    useEffect(() => {
        if (profileImage != null && appUser != null) {
            setAppUser({id: appUser.id, name: appUser?.name, profileImageUrl: profileImage, registeredDate: appUser?.registeredDate})
        }
    }, [appUser, profileImage]);

    useEffect(() => {
        setIsNameUnique(false)

        if (!isLoading && name != appUser?.name && name != '' && name.length >= 3) {
            BooksitoutServer
                .get(`/v1/users/settings/profile/name/availability?name=${name}`)
                .then(res => setIsNameUnique(res.data.isAvailable))
        }
    }, [name]);

    const handleEditUser = (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('이름을 입력해 주세요.')
            document.getElementById('input-name')?.focus()
            return
        }

        const formData = new FormData()
        formData.append('image', imageData ?? '')
        formData.append('name', name)

        BooksitoutServer
            .put(`/v1/users/settings/profile`, formData)
            .then((res) => {
                toast.success('프로필을 수정했어요')
                setAppUser(res.data)
                setImageData(null)
                setProfileImage(null)
            })
            .catch(() => toast.error('오류가 났어요. 잠시 후 다시 시도해 주세요.'))
    }

    if (isLoading) {
        return <></>
    }

    if (isError || appUser == null) {
        return <NoContent message={'유저가 없거나 탈퇴한 유저에요.'}/>
    }

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.settings/>}
                title={'프로필 설정'}
                currentKey={'PROFILE'}
                buttons={RouteTitleConfig.Settings}
            />

            <RowSpacer/>
            <ProfileUserCard user={appUser}/>

            <RowSpacer/>
            <Card>
                <CardBodyContentContainer>
                    <Form className='row justify-content-center m-0' onSubmit={handleEditUser}>
                        <div className='col-12 mb-3'>
                            <h4 className='mb-3'>프로필 사진</h4>
                            <Card {...getRootProps()}>
                                <CardBodyContentContainer className={'text-center clickable'}>
                                    <input {...getInputProps()} />

                                    <ImageIcon className='text-book h3'/>

                                    <p className='m-0 text-secondary'>
                                        {isDragActive ? (
                                            <span className='text-book fw-bold'>맞아요! 여기에 올려 주세요</span>
                                        ) : (
                                            <span className='fw-bold'>여기에 이미지를 올리거나 클릭해서 올릴 이미지를 선택할 수 있어요</span>
                                        )}
                                    </p>
                                </CardBodyContentContainer>
                            </Card>
                        </div>

                        <RowSpacer/>

                        <div className='col-12 mb-3'>
                            <h4 className='mb-3'>이름</h4>
                            <Form.Control onChange={(e) => setName(e.target.value)} value={name} id='input-name'/>

                            <RowSpacer size={5}/>
                            <div className={'ps-3'}>
                                {
                                    name == appUser.name ?
                                        <p></p>
                                        :
                                        name == '' ?
                                            <ErrorText>이름을 입력해 주세요</ErrorText>
                                            :
                                            name.length < 3 ?
                                                <ErrorText>3글자 이상의 이름을 입력해 주세요</ErrorText>
                                                :
                                                isNameUnique ? (
                                                    <SuccessText>사용 가능한 이름이에요</SuccessText>
                                                ) : (
                                                    <ErrorText>이미 사용중인 이름이에요</ErrorText>
                                                )
                                }
                            </div>
                        </div>

                        <div className='col-12 col-md-6 mb-3'>
                            <Button
                                type='submit'
                                variant='book'
                                className='w-100 mt-4 mt-md-3'
                                disabled={((name == appUser.name && profileImage == null) || !isNameUnique || name.length < 3) && profileImage == null}>
                                저장하기
                            </Button>
                        </div>
                    </Form>
                </CardBodyContentContainer>
            </Card>
            <RowSpacer/>
        </RouteContainer>
    )
}

const SuccessText = styled.p`
    font-weight: bold;
    color: var(--primary-color)
`

const ErrorText = styled.p`
    font-weight: bold;
    color: var(--danger)
`

export default SettingsProfileRoute