import React from 'react'
import styled from 'styled-components';
import AppUserWithBooksResponse from "../AppUserWithBooksResponse";
import userIcon from "../../../common/topnav/user.png";
import ColSpacer from "../../../common/styles/ColSpacer";
import Spacer from "../../../common/Spacer";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";

interface Props {
    appUserWithBooks: AppUserWithBooksResponse
}

const ProfileListItem: React.FC<Props> = ({appUserWithBooks}) => {
    return (
        <a href={`/users/${appUserWithBooks.user.id}`}>
            <CardBodyContentContainer>
                <ProfileContainer>
                    {appUserWithBooks.user.profileImageUrl != null
                        ?
                        <ProfileImage src={appUserWithBooks.user.profileImageUrl} alt={appUserWithBooks.user.name}/>
                        :
                        <ProfileImage src={userIcon} alt={appUserWithBooks.user.name}/>
                    }

                    <ColSpacer/>

                    <ProfileInfoContainer>
                        <ProfileName>{appUserWithBooks.user.name}</ProfileName>

                        <ProfileDate>
                            {appUserWithBooks.user.registeredDate[0]}년
                            {' '}
                            {appUserWithBooks.user.registeredDate[1]}월
                            {' '}
                            {appUserWithBooks.user.registeredDate[2]}일
                        </ProfileDate>
                    </ProfileInfoContainer>

                    <Spacer/>

                    <BookListContainer>
                        {
                            appUserWithBooks.books.map(book =>
                                <BookCover src={book.cover ?? userIcon} alt={book.title}/>
                            )
                        }
                    </BookListContainer>
                </ProfileContainer>
            </CardBodyContentContainer>
        </a>
    )
}

const ProfileContainer = styled.div`
    display: flex;
`

const ProfileImage = styled.img.attrs({
    className: 'rounded'
})`
    width: 50px;
    height: 50px;
`

const ProfileInfoContainer = styled.div`
    max-width: 200px;
`

const ProfileName = styled.h1.attrs({
    className: 'clamp-1-line'
})`
    font-size: 1.5rem;
    font-weight: bold;
`

const ProfileDate = styled.h1.attrs({
    className: 'text-secondary'
})`
    font-size: 1rem;
`

const BookListContainer = styled.div`
    padding-left: 10px;
`

const BookCover = styled.img.attrs({
    className: 'rounded d-none d-md-inline'
})`
    height: 60px;
    padding-left: 3px;
    padding-right: 3px;
`

export default ProfileListItem