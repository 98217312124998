import {Card} from 'react-bootstrap'
import CardTitle from '../../../common/styles/CardTitle';
import booksitoutIcon from '../../../config/BooksitoutIcon';
import CardBodyContainer from '../../../common/styles/CardBodyContainer';
import useAppUserProfiles from "../../appUsers/useAppUserProfiles";
import ProfileListItem from "../../appUsers/profiles/ProfileListItem";
import RowSpacer from "../../../common/styles/RowSpacer";

const IndexBookCard = () => {
    const [appUserWithBooks, isLoading] = useAppUserProfiles(10)

    return (
        <Card>
            <CardBodyContainer>
                <CardTitle
                    icon={<booksitoutIcon.book/>}
                    title={'서재 구경하기'}
                    subTitle={'다른 사람의 서재를 구경할 수 있어요'}
                    url='/users'
                />

                {
                    isLoading
                        ?
                        <></>
                        :
                        appUserWithBooks.map(appUserWithBook =>
                            <>
                                <ProfileListItem appUserWithBooks={appUserWithBook}/>
                                <RowSpacer size={10}/>
                            </>
                        )
                }
            </CardBodyContainer>
        </Card>
    )
}

export default IndexBookCard