import aladin from '../images/square/aladin.png'
import naver from '../images/square/naver.png'
import yes24 from '../images/square/yes24.png'
import kyobo from '../images/square/kyobo.png'

const WebsiteImages = {
    aladin: aladin,
    naver: naver,
    yes24: yes24,
    kyobo: kyobo
}

export default WebsiteImages