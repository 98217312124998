import React from 'react'
import styled from 'styled-components';
import {useParams} from "react-router";
import RouteContainer from '../../../common/styles/RouteContainer';
import {Card} from "react-bootstrap";
import useBookIsbn from "./useBookIsbn";
import NoContent from "../../../common/NoContent";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import {BookLinkType} from "./BookIsbnResponse";
import WebsiteImages from "../../../data/WebsiteImages";
import Spacer from "../../../common/Spacer";

const BookIsbnRoute = () => {
    const {isbn} = useParams()
    const [book, isLoading, isError] = useBookIsbn(isbn ?? null)

    if (isLoading) {
        return <></>
    }

    if (book == null || isError) {
        return (
            <NoContent message={`찾으시는 책이 없어요 (isbn = ${isbn})`}/>
        )
    }

    return (
        <RouteContainer>
            <RowSpacer/>
            <Card>
                <CardBodyContentContainer height={400}>
                    <Container>
                        <BookCoverContainer>
                            <BookCover src={book.cover} alt={book.title}/>
                        </BookCoverContainer>

                        <BookInfoContainer>
                            <div>
                                <BookTitle>{book.title}</BookTitle>
                                <BookAuthor>{book.author}</BookAuthor>
                            </div>

                            <Spacer/>

                            <div className={'mt-5 mt-md-0'}>
                                <BookLink type={'NAVER'} url={book.externalLinks.naver}/>
                                <BookLink type={'ALADIN'} url={book.externalLinks.aladin}/>
                                <BookLink type={'YES24'} url={book.externalLinks.yes24}/>
                                <BookLink type={'KYOBO'} url={book.externalLinks.kyobo}/>
                            </div>
                        </BookInfoContainer>
                    </Container>
                </CardBodyContentContainer>
            </Card>

            <RowSpacer/>
            <Card>
                <CardBodyContentContainer height={400}>
                    <Container>
                        <Title>소개</Title>
                        {book.description}
                    </Container>
                </CardBodyContentContainer>
            </Card>

            <RowSpacer/>
            <Card>
                <CardBodyContentContainer height={400}>
                    <Container>
                        <Title>추가 정보</Title>

                        <ul>
                            <li>출판사 <Info>{book.publisher}</Info></li>
                            <li>출판년도 <Info>{book.publicationYear}</Info></li>
                            <li>페이지 수 <Info>{book.page}</Info></li>
                        </ul>
                    </Container>
                </CardBodyContentContainer>
            </Card>

            <RowSpacer/>
        </RouteContainer>
    )
}

const Container = styled.div.attrs({
    className: 'row'
})`
    height: 100%;
    padding: 20px;
`

const Title = styled.h1`
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0;
    margin-bottom: 15px;
`

const Info = styled.span.attrs({
    className: 'text-book'
})`
    font-weight: bold;
`

const BookCoverContainer = styled.div.attrs({
    className: 'col-12 col-sm-6 col-md-4 col-xl-3'
})`
    height: 100%;
    align-content: center;
`

const BookInfoContainer = styled.div.attrs({
    className: 'col-12 col-sm-6 col-md-8 col-xl-9'
})`
    padding-left: 30px;
    padding-top: 30px;

    display: flex;
    flex-direction: column;
`

const BookCover = styled.img.attrs({
    className: 'img-fluid rounded'
})``

const BookTitle = styled.h1`
    font-size: 2rem;
    font-weight: bold;
`

const BookAuthor = styled.h2.attrs({
    className: 'text-secondary'
})`
    font-size: 1.5rem;
    font-weight: bold;
`

interface BookLinkProps {
    type: BookLinkType,
    url: string | null
}

const BookLink: React.FC<BookLinkProps> = ({type, url}) => {
    return (
        <LinkATag href={url} target={'_blank'}>
            {
                type == 'NAVER' ?
                    <LinkImage src={WebsiteImages.naver} alt=''/> :
                    type == 'ALADIN' ?
                        <LinkImage src={WebsiteImages.aladin}/> :
                        type == 'YES24' ?
                            <LinkImage src={WebsiteImages.yes24}/> :
                            type == 'KYOBO' ?
                                <LinkImage src={WebsiteImages.kyobo}/> :
                                <></>
            }
        </LinkATag>
    )
}

interface LinkATagProps {
    href: string | null
}

const LinkATag = styled.a.attrs<LinkATagProps>({
    className: 'border'
})`
    ${(props) => props.href == null && `
        pointer-events: none;
        color: grey;
        text-decoration: none;
        opacity: 0.5;
  `}

    margin-left: 5px;
    margin-right: 5px;
`;

const LinkImage = styled.img.attrs({
    className: 'rounded hover-effect clickable',
})`
    width: 50px;
    height: 50px;
`

export default BookIsbnRoute
