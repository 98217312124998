import React from 'react'
import styled from 'styled-components';
import BooksitoutProductStatus from "./type/BooksitoutProductStatus";
import BooksitoutProductCard from "./BooksitoutProductCard";
import RouteContainer from "../common/styles/RouteContainer";
import RowSpacer from "../common/styles/RowSpacer";
import useLanguage from "../common/language/useLanguage";
import getLanguageValue from "../common/language/LanguageInfo";
import BooksitoutProductsData from "../data/products/BooksitoutProductsData";

const BooksitoutProductsRoute = () => {
    const language = useLanguage()

    let TITLE = getLanguageValue('BooksitoutProductsRoute.RouteTitle', language)
    let SUBTITLE = getLanguageValue('BooksitoutProductsRoute.RouteSubTitle', language)

    return (
        <RouteContainer>
            <RowSpacer/>
            <RouteTitle>{TITLE}</RouteTitle>
            <RouteSubTitle>{SUBTITLE}</RouteSubTitle>

            <Divider/>

            <Row className={'text-center'}>
                {BooksitoutProductsData
                    .filter(portfolio => portfolio.status === BooksitoutProductStatus.PRODUCTION)
                    .map(product =>
                        <div className="p-0 mb-4">
                            <BooksitoutProductCard product={product}/>
                        </div>
                    )
                }
            </Row>
            <RowSpacer/>
        </RouteContainer>
    )
}

const Divider = styled.div.attrs({
    className: 'mb-5',
})``

const Row = styled.div.attrs({
    className: 'row container ps-0',
})`
    justify-content: center;
    margin: 0;
`

const RouteTitle = styled.h1`
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
`

const RouteSubTitle = styled.h5.attrs({
    className: 'text-secondary',
})`
    text-align: center;
`

export default BooksitoutProductsRoute
