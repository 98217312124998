import BooksitoutWebsiteIcon from "../icons/BooksitoutWebsiteIcon";
import BooksitoutDemoImage from "../demos/BooksitoutDemoImage";
import BooksitoutProductType from "../../../booksitout/type/BooksitoutProductType";
import BooksitoutProductStatus from "../../../booksitout/type/BooksitoutProductStatus";

const booksitoutProductSubstrack: BooksitoutProductType = {
	id: 'substrack',
	status: BooksitoutProductStatus.PRODUCTION,
	name: {
		english: 'Substrack',
		url: "substrack"
	},
	icon: BooksitoutWebsiteIcon.substrack,
	link: [
		{
			link: 'https://apps.apple.com/app/%EA%B5%AC%EB%8F%85-%EB%8F%8C%EB%B3%B4%EB%AF%B8/id6451234943',
			icon: BooksitoutWebsiteIcon.appleAppstore,
			border: false,
		},
	],
	featuresCount: 3,
	demoImages: [BooksitoutDemoImage.substrack],
	thumbnailImage: BooksitoutDemoImage.substrack,
	detailImage: BooksitoutDemoImage.substrack,
	privacyPolicyDates: ['2023-08-03']
}

export default booksitoutProductSubstrack