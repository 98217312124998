import React from 'react'
import styled from 'styled-components';
import ColSpacer from "../../../common/styles/ColSpacer";
import Spacer from "../../../common/Spacer";
import LoadingBar from "../../../common/LoadingBar";
import bookCover from '../../../images/placeholder/default-book-cover-loading.png'
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";

const ProfileListItemLoading = () => {
    return (
        <CardBodyContentContainer>
            <ProfileContainer>
                <ColSpacer/>

                {/*<ProfileInfoContainer>*/}
                {/*    <ProfileName><LoadingBar size={10}/></ProfileName>*/}

                {/*    <ProfileDate>*/}
                {/*        <LoadingBar size={2}/>년*/}
                {/*        {' '}*/}
                {/*        <LoadingBar size={2}/>월*/}
                {/*        {' '}*/}
                {/*        <LoadingBar size={2}/>일*/}
                {/*    </ProfileDate>*/}
                {/*</ProfileInfoContainer>*/}

                <Spacer/>

                <BookListContainer>
                    {
                        Array.from({length: 10}).map(_ =>
                            <BookCover src={bookCover} alt={'placeholder book cover'}/>
                        )
                    }
                </BookListContainer>
            </ProfileContainer>
        </CardBodyContentContainer>
    )
}

const ProfileContainer = styled.div`
    display: flex;
`

const ProfileImage = styled.img.attrs({
    className: 'rounded'
})`
    width: 50px;
    height: 50px;
`

const ProfileInfoContainer = styled.div`
    display: flex;
    max-width: 200px;
`

const ProfileName = styled.h1.attrs({
    className: 'clamp-1-line'
})`
    font-size: 1.5rem;
    font-weight: bold;
`

const ProfileDate = styled.h1.attrs({
    className: 'text-secondary'
})`
    font-size: 1rem;
`

const BookListContainer = styled.div`
    padding-left: 10px;
`

const BookCover = styled.img.attrs({
    className: 'rounded d-none d-md-inline'
})`
    height: 60px;
    padding-left: 3px;
    padding-right: 3px;
`

export default ProfileListItemLoading
