import React from 'react'
import { Card } from 'react-bootstrap'
import parse from 'html-react-parser'
import BooksitoutProductType from "../type/BooksitoutProductType";
import {BooksitoutCard, BooksitoutCardTitle} from "../BooksitoutCard";
import useLanguage from "../../common/language/useLanguage";
import getLanguageValue from "../../common/language/LanguageInfo";

interface Props {
    product: BooksitoutProductType
}

const ProductDetailDescriptionCard: React.FC<Props> = ({ product }) => {
	const language = useLanguage()

	let TITLE = getLanguageValue(`ProductDetailDescriptionCard.Title`, language)

	return (
		<BooksitoutCard>
			<Card.Body>
				<BooksitoutCardTitle>{TITLE}</BooksitoutCardTitle>

				<p>{parse(getLanguageValue(`Products.${product.id}.description.long`, language))}</p>
			</Card.Body>
		</BooksitoutCard>
	)
}

export default ProductDetailDescriptionCard