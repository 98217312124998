import React from 'react'
import {Card} from 'react-bootstrap'
import BooksitoutProductType from "../type/BooksitoutProductType";
import {BooksitoutCard, BooksitoutCardTitle} from "../BooksitoutCard";
import useLanguage from "../../common/language/useLanguage";
import getLanguageValue from "../../common/language/LanguageInfo";

interface Props {
    product: BooksitoutProductType
}

const ProductDetailFeaturesCard: React.FC<Props> = ({product}) => {
    const language = useLanguage()

    // Translations
    let TITLE = getLanguageValue(`ProductDetailFeaturesCard.Title`, language)

    return (
        <BooksitoutCard>
            <Card.Body>
                <BooksitoutCardTitle>{TITLE}</BooksitoutCardTitle>

                <ul>
                    {Array.from({ length: product.featuresCount }).map((_, index) => {
                        return <li key={index}>{getLanguageValue(`Products.${product.id}.features.${index + 1}`, language)}</li>;
                    })}
                </ul>
            </Card.Body>
        </BooksitoutCard>
    )
}

export default ProductDetailFeaturesCard