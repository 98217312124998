import React, {useEffect} from 'react'
import RouteTitle from "../../../common/RouteTitle/RouteTitle";
import booksitoutIcon from "../../../config/BooksitoutIcon";
import RouteTitleConfig from "../../../config/RouteTitleConfig";
import RouteContainer from "../../../common/styles/RouteContainer";
import RowSpacer from "../../../common/styles/RowSpacer";
import NoContent from "../../../common/NoContent";
import OAuthConfig from "../../../config/OAuthConfig";
import useAppUserSettingsPersonalInfo from "./useAppUserSettingsPersonalInfo";
import styled from "styled-components";
import {Card, CardTitle} from "react-bootstrap";
import CardBodyContentContainer from "../../../common/styles/CardBodyContentContainer";
import BooksitoutIcon from "../../../config/BooksitoutIcon";
import useLoginStore from "../../login/useLoginStore";
import {useNavigate} from "react-router-dom";

const SettingsPersonalInfoRoute = () => {
    const [info, isLoading, isError] = useAppUserSettingsPersonalInfo()

    const navigate = useNavigate()
    const {isLoggedIn} = useLoginStore()
    useEffect(() => {
        if (!isLoggedIn()) {
            navigate(`/`)
        }
    }, []);

    return (
        <RouteContainer>
            <RouteTitle
                icon={<booksitoutIcon.settings/>}
                title={'개인정보 설정'}
                currentKey={'PERSONAL'}
                buttons={RouteTitleConfig.Settings}
            />
            <RowSpacer/>

            {
                isLoading ? <></> :
                    isError ? <NoContent message={'오류가 났어요'}/> :
                        <Container>
                            <Card style={{minHeight: '200px'}}>
                                <CardBodyContentContainer>
                                    <CardTitle>연결된 이메일</CardTitle>
                                    <RowSpacer size={10}/>

                                    <ul>
                                        {
                                            info?.emails.map(email =>
                                                <li>{email}</li>
                                            )
                                        }
                                    </ul>
                                </CardBodyContentContainer>
                            </Card>
                            <RowSpacer/>

                            <Card>
                                <CardBodyContentContainer>
                                    <CardTitle>계정 추가로 연결하기</CardTitle>
                                    <RowSpacer/>

                                    <OAuthContainer>
                                        {
                                            OAuthConfig.map(oauth =>
                                                <>
                                                    {
                                                        isOAuthAdded(oauth.id) &&
                                                        <OAuthAdded/>
                                                    }

                                                    <a href={oauth.addToExistingUrl}
                                                       style={{pointerEvents: (!oauth.active || isOAuthAdded(oauth.id)) ? 'none' : 'auto'}}>
                                                        <OAuthButton src={oauth.image} alt="" active={oauth.active && !isOAuthAdded(oauth.id)}/>
                                                    </a>
                                                </>
                                            )
                                        }
                                    </OAuthContainer>
                                    <RowSpacer/>
                                </CardBodyContentContainer>
                            </Card>
                            <RowSpacer/>
                        </Container>
            }
        </RouteContainer>
    )

    function isOAuthAdded(oauthId: number) {
        return (info?.isKakaoAdded && oauthId === 3) ||
            (info?.isNaverAdded && oauthId === 4) ||
            (info?.isGoogleAdded && oauthId === 1)
    }
}

const Container = styled.div``

const OAuthContainer = styled.div`
    text-align: center;
`;

interface OAuthButtonProps {
    active: boolean
}

const OAuthButton = styled.img.attrs((props) => ({
    // @ts-ignore
    className: `img-fluid ms-2 me-2 ms-md-3 me-md-3 rounded ${(!props.active && 'opacity-50')} hover-effect`
}))<OAuthButtonProps>`
    width: 50px;
`;

const OAuthAdded = styled(BooksitoutIcon.check)`
    font-size: 1.5rem;
    position: absolute;
    color: var(--primary-color);
`

export default SettingsPersonalInfoRoute
