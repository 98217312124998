import booksitout from './logo/booksitout.png'
import purrfitTracker from './logo/purrfitTracker.png'
import substrackIcon from './logo/substrack.png'
import featuredDays from './logo/featureddaysicon.png'

const WebsiteLogo = {
    booksitout: booksitout,
    purrfitTracker: purrfitTracker,
    substrackIcon: substrackIcon,
    featuredDays: featuredDays,
}

export default WebsiteLogo