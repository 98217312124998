import {BooksitoutLanguage} from "../common/language/BooksitoutLanguage";

const PrivacyData: Map<string, string> = new Map([
    // BOOKSITOUT
    [`2023-07-01 KOREAN`, `
            <b>책잇아웃</b>은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
        
            <br/>
            <br/>
        
            <h4>제1조(개인정보의 처리 목적)</h4>
            
            <b>책잇아웃</b>은 다음의 목적을 위해 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
            <br/>
            <br/>
            1. 홈페이지 회원가입 및 관리
            <br/>
            회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리 목적으로 개인정보를 처리합니다.
            <br/>
            
            <br/>
            
            2. 재화 또는 서비스 제공
            <br/>
            서비스 제공, 요금결제·정산을 목적으로 개인정보를 처리합니다.
            <br/>
            
            <br/>
            <br/>
            
            <h4>제2조(개인정보의 처리 및 보유 기간)</h4>
            ① <b>책잇아웃</b>은 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.
            <br/>
            ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
            <br/>
            <br/>
            
            1. 홈페이지 회원가입 및 관리
            <ul>
                <li>홈페이지 회원가입 및 관리와 관련한 개인정보는 수집.이용에 관한 동의일로부터 5년까지 위 이용목적을 위하여 보유.이용됩니다.</li>    
                <li>보유근거 : 회원탈퇴 시 파기</li>    
                <li>관련법령 : 표시/광고에 관한 기록, 6개월</li>    
            </ul>
            
            2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
            <br/>
            <br/>
            
            3) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
            
            <br/>
            <br/>
            <br/>
            <h4>제3조(처리하는 개인정보의 항목)</h4>
            
            <b>책잇아웃</b>은 다음의 개인정보 항목을 처리하고 있습니다.
            <br/>
            <br/>
            
            홈페이지 회원가입 및 관리
            <ul>
                <li>필수항목 : 이름, 이메일</li>    
                <li>선택항목 : 프로필 이미지</li>    
            </ul>
            
            <br/>
            
            <h4>제4조(개인정보의 파기절차 및 파기방법)</h4>
            <ol>
                <li><b>책잇아웃</b>은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>    
                <li>정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</li>    
                <li>
                    개인정보 파기의 절차 및 방법은 다음과 같습니다.
                    <ul>
                        <li>파기절차: <b>책잇아웃</b> 은 파기 사유가 발생한 개인정보를 선정하고, <b>책잇아웃</b>의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</li>
                        <li>파기방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다</li>
                    </ul>
                </li>
            </ol>
            
            <br/>
            <h4>제5조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)</h4>
            
            <ol>
                <li>정보주체는 <b>책잇아웃</b>에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</li>
                <li>제1항에 따른 권리 행사는<b>책잇아웃</b>에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 <b>책잇아웃</b>은 이에 대해 지체 없이 조치하겠습니다.</li>
                <li>제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
                <li>개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</li>
                <li>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
                <li><b>책잇아웃</b>은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
            </ol>   
            
            <br/>
            <h4>제6조(개인정보의 안전성 확보조치에 관한 사항)</h4>
            
            <b>책잇아웃</b>은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
            <br/>
            <br/>
            
            <ol>
            <li>
                개인정보에 대한 접근 제한
                <ul>
                    <li>개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.</li>
                </ul>    
            </li>
            
            <li>
                개인정보의 암호화
                <ul>
                    <li>이용자의 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.</li>
                </ul>
            </li>
            
            <li>
                해킹 등에 대비한 기술적 대책
                <ul>
                    <li><b>책잇아웃</b>은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</li>
                </ul>
            </li>
            </ol>  
            
            <br/>
            <h4>제7조(개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)</h4>
            
            <b>책잇아웃</b> 은 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다. (로그인 세션 유지를 위한 목적 제외)
            <br/>
            <br/>
            
            <br/>
            <h4>제8조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)</h4>
            <b>책잇아웃</b>은 온라인 맞춤형 광고 등을 위한 행태정보를 수집·이용·제공하지 않습니다.
        
            <br/>
            <br/>
            <br/>
            <h4>제9조(가명정보를 처리하는 경우 가명정보 처리에 관한 사항)</h4>
            해당없음
        
            <br/>
            <br/>
            <br/>
            <h4>제10조 (개인정보 보호책임자에 관한 사항)</h4>
            <ol>
                <li>
                    <b>책잇아웃</b>은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                    <br/>
                    <br/> 
                    개인정보 보호책임자
                    <ul>
                        <li>성명 : 박진겸</li>
                        <li>직책 : 대표</li>
                        <li>직급 : 대표</li>
                        <li>연락처 : booksitout@gmail.com</li>
                    </ul>
                </li>
                <br/>
                
                <li>정보주체께서는 <b>책잇아웃</b>의 서비스을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. <b>책잇아웃은</b> 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.</li>
            </ol>
            
            <br/>
            <br/>
            <h4>제11조(개인정보의 열람청구를 접수·처리하는 부서)</h4>
            정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
            <b>책잇아웃</b>은 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
            
            <br/>
            <br/>
            <br/>
            <h4>제12조(정보주체의 권익침해에 대한 구제방법)</h4>
            
            정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
            <br/>
            <br/>
            
            <ol>
            <li>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
            <li>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
            <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
            <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
            </ol>
            
            「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
            <br/>
            ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고해 주세요.
        
            <br/>
            <br/>
            <br/>
            <h4>제13조(개인정보 처리방침 변경)</h4>
            이 개인정보처리방침은 2023년 7월 1부터 적용됩니다.
    `],
    [`2023-07-01 ENGLISH`, `
        <div class="pt-5"/>
        <h4 class="text-center">We do not provide service in English</h4>
        <div class="pb-5"/>
    `],
    [`2023-07-01 JAPANESE`, `
        <div class="pt-5"/>
        <h4 class="text-center">日本語ではサービスされてません</h4>
        <div class="pb-5"/>
    `],

    // SUBSTRACK
    [`2023-08-03 KOREAN`, `
        <h4>1 - 고정지출 돌보미가 수집하는 개인정보</h4>
        <ul>
            <li>고정지출 돌보미는 어떠한 개인정보도 수집하지 않습니다.</li>            
            <li>입력하신 모든 데이터는 디바이스 (iPhone, iPad, Mac)에만 저장되고, 선택하신 경우에는 iCloud에 백업됩니다.</li>
            <li>앱을 기능을 향상시키기 위해 익명의 사용 데이터를 수집할 수도 있습니다.</li>            
        </ul>
    `],
    [`2023-08-03 ENGLISH`, `
        <h4>1 - Data Substrack Collect</h4>
        <ul>
            <li>We do not collect any personal data related to you.</li>            
            <li>All the data you create is only stored in local (iPhone, iPad, Mac) and iCloud.</li>            
            <li>We may collect anonymous app usage data to improve the app</li>            
        </ul>
    `],
    [`2023-08-03 JAPANESE`, `
        <h4>1 - 固定費マスターが収集する個人情報</h4>
        <ul>
            <li>固定費マスターは、いかなる個人情報も収集しません。</li>
            <li>入力されたすべてのデータはデバイス（iPhone、iPad、Mac）にのみ保存され、選択した場合にはiCloudにバックアップされます。</li>
            <li>アプリの機能向上のために、匿名の使用データを収集することがあります。</li>
        </ul>
    `],

    // PURRFIT TRACKER
    [`2023-10-30 KOREAN`, `
        <h4>1 - 수입 관리냥 수집하는 개인정보</h4>
        <ul>
            <li>수입 관리냥은 어떠한 개인정보도 수집하지 않습니다.</li>            
            <li>입력하신 모든 데이터는 디바이스 (iPhone, iPad, Mac)에만 저장되고, 선택하신 경우에는 iCloud에 백업됩니다.</li>
            <li>앱을 기능을 향상시키기 위해 익명의 사용 데이터를 수집할 수도 있습니다.</li>            
        </ul>
    `],
    [`2023-10-30 ENGLISH`, `
        <h4>1 - Data Purrfit Tracker Collect</h4>
        <ul>
            <li>We do not collect any personal data related to you.</li>            
            <li>All the data you create is only stored in local (iPhone, iPad, Mac) and iCloud.</li>            
            <li>We may collect anonymous app usage data to improve the app</li>            
        </ul>
    `],
    [`2023-10-30 JAPANESE`, `
        <h4>1 - 収入管理ニャンが収集する個人情報</h4>
        <ul>
            <li>収入管理ニャンは、いかなる個人情報も収集しません。</li>
            <li>入力されたすべてのデータはデバイス（iPhone、iPad、Mac）にのみ保存され、選択した場合にはiCloudにバックアップされます。</li>
            <li>アプリの機能向上のために、匿名の使用データを収集することがあります。</li>
        </ul>
    `],

    // FEATURED DAYS
    [`2024-08-25 KOREAN`, `
        <h4>1 - 인생의 날이 수집하는 개인정보</h4>
        <ul>
            <li>인생의 날은 어떠한 개인정보도 수집하지 않습니다.</li>            
            <li>입력하신 모든 데이터는 디바이스 (iPhone, iPad, Mac)에만 저장되고, 선택하신 경우에는 iCloud에 백업됩니다.</li>
            <li>앱을 기능을 향상시키기 위해 익명의 사용 데이터를 수집할 수도 있습니다.</li>            
        </ul>
    `],
    [`2024-08-25 ENGLISH`, `
        <h4>1 - Data Featured Days Collect</h4>
        <ul>
            <li>We do not collect any personal data related to you.</li>            
            <li>All the data you create is only stored in local (iPhone, iPad, Mac) and iCloud.</li>            
            <li>We may collect anonymous app usage data to improve the app</li>            
        </ul>
    `],
    [`2024-08-25 JAPANESE`, `
        <h4>1 - 人生の日が収集する個人情報</h4>
        <ul>
            <li>人生の日は、いかなる個人情報も収集しません。</li>
            <li>入力されたすべてのデータはデバイス（iPhone、iPad、Mac）にのみ保存され、選択した場合にはiCloudにバックアップされます。</li>
            <li>アプリの機能向上のために、匿名の使用データを収集することがあります。</li>
        </ul>
    `],
])

function getPrivacyData(date: string, language: BooksitoutLanguage): string | null {
    return PrivacyData.get(`${date} ${language.toUpperCase()}`) ?? null
}

export default getPrivacyData